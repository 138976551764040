import React from "react";

export const Header = ({ scrollTobuynow }) => {
  const handleClick = () => {
    window.open(
      "/img/SmartContract_Audit_Solidproof_CrudoProtocol.pdf",
      "_blank"
    );
  };
  return (
    <>
      <header class="home" id="home">
        <div class="bgg1"></div>
        <div class="bgg2"></div>
        <div class="banner_content">
          <div class="container  position-relative">
            <div class="row">
              <div class="col-md-8 m-auto text-center">
                <h1>
                  Powering the Oilconomy:
                  <br />
                  <span class="">Crudo Protocol</span>
                </h1>
                {/* <div class="ling_home"></div> */}
                <div class="login_btns ttu mt-4 mt-md-5">
                  <a
                    href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v1"
                    class="btn btn_border mr-2 mr-md-4"
                    target="_blank"
                  >
                    Whitepaper
                  </a>

                  <a href="/#buy-now" onClick={scrollTobuynow} class="btn">
                    Buy Now
                  </a>
                  {/* <a href="/how-to-buy" onClick={scrollTobuynow} class="btn">
                   How to Buy
                  </a> */}
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-6 m-auto ">
                <div className="mt-4 mt-md-5  text-center">
                  <a onClick={handleClick}>
                    <img
                      className="img-fluid"
                      src="/img/check.png"
                      alt="check"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
