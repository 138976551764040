import React, { } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Home } from "./components/HomePage/Home";
import { TransactionDetails } from "./components/TransactionDetails/TransactionDetails";
import { SignUp } from "./components/auth/SignUp";
import { TermsOfUse } from "./components/Terms of Use/TermsOfUse";
import { PrivacyPolicy } from "./components/Privacy Policy/PrivacyPolicy";
import { HowToBuy } from "./components/How To Buy/HowToBuy";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {" "}
         
          <Route path="/" element={<Home />}  />
          <Route path="/referral/:walletAddress" element={<Home />} />
          <Route path="/sign-up" element={<SignUp />} />
          
          <Route path="/transaction-details" element={<TransactionDetails />} /> 
          <Route path="/terms-of-use" element={<TermsOfUse />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/how-to-buy" element={<HowToBuy />} />
        </Routes>
      </Router>
      <ToastContainer
      className= 'custom-toast'
      position= "top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
      {/* <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </div>
  );
}

export default App;
